import { Button, Radio } from "antd";
import React, { useState } from "react";
import { LogoContainer } from "../LogoContainer/LogoContainer";
import RbiLogin from "../RbiLogin/RbiLogin";
import "../RbiLogin/RbiLogin.css";
import { setRelationship } from "../../../redux/actions/rbi";
import { useDispatch } from "react-redux";

const RelationshipCheckbox = () => {
  const [selectedValue, setSelectedValue] = useState("");
  const [showOtp, setShowOtp] = useState(false);

  const dispatch = useDispatch();

  const options = [
    { label: "Father", value: "Father" },
    { label: "Mother", value: "Mother" },
    { label: "Spouse", value: "Spouse" },
    { label: "Child", value: "child" },
  ];

  const handleSelect = (value) => {
    setSelectedValue(value);
    dispatch(setRelationship(value));
  };

  // Common styles for radio div
  const getRadioStyle = (isSelected) => ({
    backgroundColor: isSelected ? " #feb818" : "#F5F5F5",
    boxShadow: "2px 2px 4px rgba(0, 0, 0, 0.1)",
    borderRadius: "20px",
    padding: "4px",
    cursor: "pointer",
    width: "100px",
    display: "flex",
    // justifyContent: "center"
  });

  const handleFamilyMembers = () => {
    if (selectedValue) {
      setShowOtp(true);
    }
  };

  return (
    <div>
      {/* <LogoContainer /> */}
      {showOtp ? (
        <RbiLogin />
      ) : (
        <div className="rbi-main-content">
          <div className="helpline">
            <h3>Helpline : 7710090107</h3>
          </div>

          <div className="login-content">
            <div className="login-text">
              <h2>Select your relationship with the employee</h2>
              <h5>Select one of the below</h5>
              <div>
                {[0, 2].map((startIndex) => (
                  <div
                    key={startIndex}
                    style={{
                      display: "flex",
                      justifyContent: "space-around",
                      marginTop: startIndex > 0 ? "20px" : "0",
                    }}
                  >
                    {options.slice(startIndex, startIndex + 2).map((option) => (
                      <div
                        key={option.value}
                        onClick={() => handleSelect(option.value)}
                        style={getRadioStyle(selectedValue === option.value)}
                      >
                        <Radio checked={selectedValue === option.value}>
                          {option.label}
                        </Radio>
                      </div>
                    ))}
                  </div>
                ))}
              </div>

              <div className="login-btn-secction">
                <Button className="login-btn" onClick={handleFamilyMembers}>
                  Get Started
                </Button>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default RelationshipCheckbox;
