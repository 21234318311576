import React from "react";
import "./MainPage.css";
import { Button } from "antd";
import { useHistory, useLocation } from "react-router-dom";
import RbiLogin from "./RbiLogin/RbiLogin";
// import apolloPharm from "./../../images/apollo-pharmacy.jpeg";
import apolloPharm from "../../images/Apollo_White_Logo.png";

// import apollo247 from "./../../images/apollo-247.png";
import apollo247 from "../../images/apollo247_white_logo.png";
// import rbiLogo from "./../../images/rbi-logo.png";
import rbiLogo from "./../../images/rbi-logo1.png";
import { LogoContainer } from "./LogoContainer/LogoContainer";
import RelationshipCheckbox from "./FamilyMember/RelationshipCheckbox";
import { useDispatch, useSelector } from "react-redux";
import { setRelationship } from "../../redux/actions/rbi";

const MainPage = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const location = useLocation();
  const mobileNumber = useSelector((state) => state?.rbi?.number);

  // Extract query parameters
  const queryParams = new URLSearchParams(location.search);
  const emp = queryParams.get("emply");
  const otp = queryParams.get("otp");

  const handleEmployee = (emp) => {
    history.push(`/ihbanarik?emply=${emp}`);
    dispatch(setRelationship(""));
  };
  const handleFamilyMember = (family) => {
    history.push(`/ihbanarik?emply=${family}`);
  };
  return (
    <>
      {emp === "employee" ? (
        <div className="main-container">
          <RbiLogin />
        </div>
      ) : emp === "family-member" ? (
        <div className="main-container">
          <RelationshipCheckbox />
        </div>
      ) : (
        <div className="main-container">
          <div className="rbi-content">
            {/* <div className="logo-container">
              <div className="top-logos">
                <img
                  className="apollo-pharm"
                  src={apolloPharm}
                  alt="apollo logo"
                />
                <img
                  className="apollo-logo-pharm"
                  src={apollo247}
                  alt="apollo logo"
                />
              </div>
              <div
                style={{
                  marginTop: "25px",
                  justifyContent: "center",
                  display: "flex",
                }}
              >
                <img className="rbi-logo" src={rbiLogo} alt="apollo logo" />
              </div>
            </div> */}
            <div>
              <h3>
                <span className="text-white">Your</span>{" "}
                <span className="text-orange">Health ,</span>{" "}
                <span className="text-white">Our</span>{" "}
                <span className="text-orange">Priority</span>{" "}
              </h3>
              <h1 className="text-white">Are you an</h1>
              <div
                style={{ gap: 10, display: "flex", justifyContent: "center" }}
              >
                <Button
                  className="btn-rounded"
                  onClick={() => handleEmployee("employee")}
                >
                  Employee?
                </Button>
                <Button
                  className="btn-rounded"
                  onClick={() => handleFamilyMember("family-member")}
                >
                  Family Member?
                </Button>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default MainPage;
