import React, { useState } from "react";
import "../RbiLogin/RbiLogin.css";
import InputField from "../../../UIComponents/Input/Input";
import { Button, Modal } from "antd";
import { useHistory, useLocation } from "react-router-dom";
import { Input, Space } from "antd";
import PatientForm from "../PatientForm/PatientForm";
import { LogoContainer } from "../LogoContainer/LogoContainer";
import errorIcon from "../../../images/error-icon.png";
import { useDispatch, useSelector } from "react-redux";
import { setMobileNumber } from "../../../redux/actions/rbi";
import { baseUrl } from "../../../utils/Helper";
import axios from "axios";

const RbiLogin = () => {
  const history = useHistory();
  const location = useLocation();
  const dispatch = useDispatch();
  let url = baseUrl();

  const [isModalOpen, setIsModalOpen] = useState(false);
  const [otp, setOtp] = useState(["", "", "", "", "", ""]);
  const [phoneNumber, setPhoneNumber] = useState("");
  const [showOtp, setShowOtp] = useState(false);
  const [errorMessage, setErrorMessage] = useState(""); // Error message for phone number
  const [otpErrorMessage, setOtpErrorMessage] = useState(""); // Error message for OTP
  const [patientForm, setPatientForm] = useState(false);

  const [formData, setFormData] = useState({
    phone: "",
  });

  const [errors, setErrors] = useState({});

  const relationship = useSelector((state) => state?.rbi?.relationship);

  const handleChange = (value, index) => {
    const newOtp = [...otp];
    newOtp[index] = value;

    if (value && index < 5) {
      document.getElementById(`otp-input-${index + 1}`).focus();
    }

    setOtp(newOtp);
  };

  // Extract query parameters

  // Validate phone number input
  const validatePhoneNumber = (number) => {
    const phoneRegex = /^[0-9]{10}$/; // Regular expression for a 10-digit phone number
    return phoneRegex.test(number);
  };

  // const handleLogin = async () => {
  //   setErrorMessage("");
  //   dispatch(setMobileNumber(formData.phone));

  //   if (!formData.phone) {
  //     setErrorMessage("Phone number cannot be empty.");
  //     return;
  //   }

  //   if (!validatePhoneNumber(formData.phone)) {
  //     setErrorMessage("Please enter a valid 10-digit phone number.");
  //     return;
  //   }

  //   try {
  //     const response = await fetch(`${url}user/rbi/send-otp`, {
  //       method: "POST",
  //       headers: {
  //         "Content-Type": "application/json",
  //       },
  //       body: JSON.stringify({ mobile: formData.phone }),
  //     });

  //     if (response.ok) {
  //       setShowOtp(true);
  //     } else {
  //       setErrorMessage("Failed to send OTP. Please try again.");
  //     }
  //   } catch (error) {
  //     console.error("Error sending OTP:", error);
  //     setErrorMessage("An error occurred. Please try again later.");
  //   }
  // };

  // const handleLogin = async () => {
  //   setErrorMessage("");
  //   dispatch(setMobileNumber(formData.phone));

  //   if (!formData.phone) {
  //     setErrorMessage("Phone number cannot be empty.");
  //     return;
  //   }

  //   if (!validatePhoneNumber(formData.phone)) {
  //     setErrorMessage("Please enter a valid 10-digit phone number.");
  //     return;
  //   }

  //   try {
  //     const response = await axios.post(`${url}user/rbi/send-otp`, {
  //       mobile: formData.phone,
  //     });

  //     if (response.status === 200) {
  //       setShowOtp(true);
  //     } else {
  //       setErrorMessage("Failed to send OTP. Please try again.");
  //     }
  //   } catch (error) {
  //     if (error.response) {
  //       // If the server responded with a status other than 2xx
  //       setErrorMessage(
  //         error.response.data?.message ||
  //           "Failed to send OTP. Please try again."
  //       );
  //     } else {
  //       setErrorMessage("An error occurred. Please try again later.");
  //     }
  //   }
  // };

  const validateForm = () => {
    let validationErrors = {};

    if (!formData.phone) {
      validationErrors.phone = "Phone number cannot be empty";
    }

    setErrors(validationErrors);
    return Object.keys(validationErrors).length === 0;
  };

  const handleLogin = async () => {
    dispatch(setMobileNumber(formData.phone));

    // if (!formData.phone) {
    //   setErrorMessage("Phone number cannot be empty.");
    //   return;
    // }

    // if (!validatePhoneNumber(formData.phone)) {
    //   setErrorMessage("Please enter a valid 10-digit phone number2.");
    //   return;
    // }

    if (validateForm()) {
      const employeeData = {
        mobile: formData.phone,
      };

      try {
        const response = await axios.post(
          `${url}user/rbi/send-otp`,
          employeeData,
          {
            headers: {
              "Content-Type": "application/json",
              Credential: true,
            },
          }
        );

        console.log("response", response);

        if (response.status === 200 || response.status === 201) {
          setShowOtp(true);
        }
        // else {
        //   setErrorMessage("Failed to send OTP. Please try again.");
        // }
      } catch (error) {
        console.log(error);
      }
    }
  };

  const [errorMess, setErrorMess] = useState("");

  const handleVerifyOtp = async () => {
    const enteredOtp = otp.join("");

    if (enteredOtp.length < 6) {
      setOtpErrorMessage("Please enter a valid 6-digit OTP.");
      return;
    }

    try {
      axios
        .post(`${url}user/rbi/verify-otp`, {
          mobile: formData.phone,
          otp: Number(enteredOtp),
          guardian: relationship ? relationship : "self",
        })
        .then((response) => {
          // if (response.status === 400) {
          //   setIsModalOpen(true);
          // }
          if (response.status === 200 || 201) {
            setPatientForm(true); // Proceed to the PatientForm if the OTP is verified
          }
        })
        .catch((error) => {
          if (error?.response?.status === 400) {
            setIsModalOpen(true);
            setErrorMess(error?.response?.data?.message);
          }

          if (error.response && error.response.status === 400) {
            setOtpErrorMessage("Incorrect OTP. Please try again.");
          } else {
            setOtpErrorMessage("An error occurred. Please try again later.");
          }
        });
    } catch (error) {
      console.error("Unexpected error:", error);
    }
  };

  const handleOk = () => {
    setIsModalOpen(false);
  };

  const handleCancel = () => {
    setIsModalOpen(false);
  };

  const handleDone = () => {
    history.push("/ihbanarik");
  };

  const handleChange1 = (e) => {
    const { name, value } = e.target;

    // Only allow numbers and limit the length to 10
    if (/^\d{0,10}$/.test(value)) {
      setFormData((prevData) => ({
        ...prevData,
        [name]: value,
      }));
    }
  };

  return (
    <>
      {/* {emp === "employee" && <LogoContainer />} */}

      {!showOtp ? (
        <div className="rbi-main-content">
          <div className="helpline">
            <h3>Helpline : 7710090107</h3>
          </div>
          <div className="login-content">
            <div className="login-text">
              <h2>Login</h2>
              <h5>Please login to continue</h5>
              <div className="login-input">
                <p>Phone Number</p>
                <InputField
                  type="text"
                  maxLength={10}
                  id="phone"
                  name="phone"
                  placeholder="Enter Your Phone Number"
                  // className={`login-phone-input-style ${
                  //   errorMessage ? "input-error" : ""
                  // }`}
                  value={formData.phone}
                  onChange={handleChange1}

                  // onChange={(e) => setPhoneNumber(e.target.value)}
                  // onChange={(e) => {
                  //   const { value } = e.target;

                  //   // Allow only numeric values
                  //   if (/^\d*$/.test(value)) {
                  //     setPhoneNumber(value);
                  //   }
                  // }}
                />
                {/* {errorMessage && (
                  <p className="error-message">{errorMessage}</p>
                )} */}
                {errors.phone && <span className="error">{errors.phone}</span>}
              </div>
              <div className="login-btn-secction">
                <h4>We will send an OTP to this number</h4>
                <Button className="login-btn" onClick={handleLogin}>
                  Login
                </Button>
              </div>
            </div>
          </div>
        </div>
      ) : (
        <>
          {!patientForm ? (
            <div className="rbi-main-content">
              <div className="login-content">
                <div className="login-text">
                  <h2>Get Your Code</h2>
                  <h5>
                    Please enter the 6-digit OTP code sent to your mobile number{" "}
                    {formData.phone}
                  </h5>
                  <div className="otp-input">
                    <Space>
                      {otp.map((digit, index) => (
                        <Input
                          key={index}
                          id={`otp-input-${index}`}
                          value={digit}
                          type="number" // Change type to "text"
                          maxLength={1} // Limit the max length to 1
                          className="otp-box"
                          style={{
                            textAlign: "center",
                            padding: "10px",
                          }}
                          onChange={(e) => handleChange(e.target.value, index)}
                          onKeyPress={(e) => {
                            if (!/[0-9]/.test(e.key)) {
                              e.preventDefault();
                            }
                          }}
                          onFocus={(e) => e.target.select()} // Optional: Select the text on focus
                        />
                      ))}
                    </Space>
                  </div>
                  {otpErrorMessage && (
                    <p className="error-message">{otpErrorMessage}</p>
                  )}
                  <div className="login-btn-secction">
                    <Button className="login-btn" onClick={handleVerifyOtp}>
                      Verify OTP
                    </Button>
                  </div>
                </div>
              </div>
            </div>
          ) : (
            <>
              <PatientForm />
            </>
          )}
        </>
      )}

      {/* Success Modal55 */}
      <Modal
        visible={isModalOpen}
        onOk={handleOk}
        onCancel={handleCancel}
        width={200}
        style={{ top: "50%", transform: "translateY(-50%)" }}
        maskClosable={false}
      >
        <div style={{ padding: "10px", textAlign: "center" }}>
          <div>
            <img
              className="user-icon-header"
              width={80}
              src={errorIcon}
              alt="user-icon"
            />
          </div>
          {/* {relationship === "child" ? (
            <h5>Cannot add more than 3 child</h5>
          ) : relationship === "Self" ? (
            <>
              <h6>Patient Already Exists</h6>
            </>
          ) : (
            <h6>You are not a registered employee please register</h6>
          )} */}
          {/* {relationship === "child" ? (
            <h5>Cannot add more than 3 child</h5>
          ) : relationship === "Self" ? (
            <>
              <h6>Patient Already Exists</h6>
            </>
          ) : (
            <h6>You are not a registered employee please register</h6>
          )} */}
          {/* {relationship === "child" ? (
            <h5>Cannot add more than 3 child</h5>
          ) : (
            <h6>{errorMess}</h6>
          )} */}

          <h6>{errorMess}</h6>

          <Button className="done-btn" onClick={handleDone}>
            Done
          </Button>
        </div>
      </Modal>
    </>
  );
};

export default RbiLogin;
