export const pincodeData = [
  { id: "400001", name: "400001" },
  { id: "400002", name: "400002" },
  { id: "400003", name: "400003" },
  { id: "400004", name: "400004" },
  { id: "400005", name: "400005" },
  { id: "400006", name: "400006" },
  { id: "400007", name: "400007" },
  { id: "400008", name: "400008" },
  { id: "400009", name: "400009" },
  { id: "400010", name: "400010" },
  { id: "400011", name: "400011" },
  { id: "400012", name: "400012" },
  { id: "400013", name: "400013" },
  { id: "400014", name: "400014" },
  { id: "400015", name: "400015" },
  { id: "400016", name: "400016" },
  { id: "400017", name: "400017" },
  { id: "400018", name: "400018" },
  { id: "400019", name: "400019" },
  { id: "400020", name: "400020" },
  { id: "400021", name: "400021" },
  { id: "400022", name: "400022" },
  { id: "400023", name: "400023" },
  { id: "400024", name: "400024" },
  { id: "400025", name: "400025" },
  { id: "400026", name: "400026" },
  { id: "400027", name: "400027" },
  { id: "400028", name: "400028" },
  { id: "400029", name: "400029" },
  { id: "400030", name: "400030" },
  { id: "400031", name: "400031" },
  { id: "400032", name: "400032" },
  { id: "400033", name: "400033" },
  { id: "400034", name: "400034" },
  { id: "400035", name: "400035" },
  { id: "400036", name: "400036" },
  { id: "400037", name: "400037" },
  { id: "400038", name: "400038" },
  { id: "400039", name: "400039" },
  { id: "400041", name: "400041" },
  { id: "400042", name: "400042" },
  { id: "400043", name: "400043" },
  { id: "400049", name: "400049" },
  { id: "400050", name: "400050" },
  { id: "400051", name: "400051" },
  { id: "400052", name: "400052" },
  { id: "400054", name: "400054" },
  { id: "400055", name: "400055" },
  { id: "400056", name: "400056" },
  { id: "400057", name: "400057" },
  { id: "400058", name: "400058" },
  { id: "400059", name: "400059" },
  { id: "400060", name: "400060" },
  { id: "400061", name: "400061" },
  { id: "400062", name: "400062" },
  { id: "400063", name: "400063" },
  { id: "400064", name: "400064" },
  { id: "400065", name: "400065" },
  { id: "400066", name: "400066" },
  { id: "400067", name: "400067" },
  { id: "400068", name: "400068" },
  { id: "400069", name: "400069" },
  { id: "400070", name: "400070" },
  { id: "400071", name: "400071" },
  { id: "400072", name: "400072" },
  { id: "400073", name: "400073" },
  { id: "400074", name: "400074" },
  { id: "400075", name: "400075" },
  { id: "400076", name: "400076" },
  { id: "400077", name: "400077" },
  { id: "400078", name: "400078" },
  { id: "400079", name: "400079" },
  { id: "400080", name: "400080" },
  { id: "400081", name: "400081" },
  { id: "400082", name: "400082" },
  { id: "400083", name: "400083" },
  { id: "400084", name: "400084" },
  { id: "400085", name: "400085" },
  { id: "400086", name: "400086" },
  { id: "400087", name: "400087" },
  { id: "400088", name: "400088" },
  { id: "400089", name: "400089" },
  { id: "400090", name: "400090" },
  { id: "400091", name: "400091" },
  { id: "400092", name: "400092" },
  { id: "400093", name: "400093" },
  { id: "400094", name: "400094" },
  { id: "400095", name: "400095" },
  { id: "400096", name: "400096" },
  { id: "400097", name: "400097" },
  { id: "400098", name: "400098" },
  { id: "400099", name: "400099" },
  { id: "400101", name: "400101" },
  { id: "400102", name: "400102" },
  { id: "400103", name: "400103" },
  { id: "400104", name: "400104" },
  { id: "400701", name: "400701" },
  { id: "400611", name: "400611" },
  { id: "400614", name: "400614" },
  { id: "400702", name: "400702" },
  { id: "400703", name: "400703" },
  { id: "400707", name: "400707" },
  { id: "400608", name: "400608" },
  { id: "400606", name: "400606" },
  { id: "400605", name: "400605" },
  { id: "400603", name: "400603" },
  { id: "400602", name: "400602" },
  { id: "400607", name: "400607" },
  { id: "400601", name: "400601" },
  { id: "400604", name: "400604" },
];
